import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import RoutesComponent from './routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import './App.css';
import NotificationComponent from './components/NotificationComponent';
import { AppProviders } from './context';


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AppProviders>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RoutesComponent />
          </PersistGate>
        </Provider>
      </AppProviders>
      <NotificationComponent />
    </ThemeProvider>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { req } from 'src/services/api';
import { toast } from 'react-toastify';

const currency = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

const renderInput = params => (
  <TextField
    {...params}
    variant="outlined"
    label="Produtos"
    placeholder="Selecionar produto"
    style={{
      backgroundColor: '#fff'
    }}
    onKeyDown={event => {
      if (event.key === 'Backspace') {
        event.stopPropagation();
      }
    }}
  />
);

const filterOptions = (options, selected) => {
  const result = [];
  const selectedUuids = selected.map(el => el.uuid);
  options.forEach(option => {
    if (!selectedUuids.includes(option.uuid)) {
      result.push(option);
    }
  });
  return result;
};

const ProductAutocomplete = ({
  selected,
  setSelected,
  validarEstoque,
  descontoPix,
  descontoOrcamento
}) => {
  const [inputValue, setInputValue] = useState('');
  const [products, setProducts] = useState([]);
  const [skip, setSkip] = useState(0);

  function calculaValorAVistaProduto(produto) {
    if (produto.desconto > 0) return produto.desconto;
    if (produto.desconto_pix > 0) {
      return produto.preco - (produto.preco * produto.desconto_pix) / 100;
    } else if (descontoOrcamento > 0) {
      return produto.preco - (produto.preco * descontoOrcamento) / 100;
    } else if (descontoPix > 0) {
      return produto.preco - (produto.preco * descontoPix) / 100;
    }
    return produto.preco;
  }

  const renderOption = option => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <img
        loading="lazy"
        width={40}
        height={40}
        src={option.images[0]?.url}
        style={{ marginRight: 10, objectFit: 'contain' }}
      />
      <p>
        {option.nome_formal} ({option.marca}) -{' '}
        <b>
          {' '}
          {currency.format(option.preco)} (à vista $
          {currency.format(calculaValorAVistaProduto(option))}){' '}
        </b>{' '}
        - MÁX: {option.quantidade_estoque}
      </p>
    </div>
  );

  async function loadProducts(filter, skip, clear = false) {
    try {
      const data = await req(
        `
        {
          readProduct(
            skip: ${skip * 50}
            take: 50
            filter: "${filter}"
          ){
            data {
              uuid
              codigo
              nome_formal
              marca
              preco
              desconto_pix
              unidade_preco
              quantidade_estoque
              images{
                uuid
                url
              }
            }
            total
          }
        }
        `
      );
      if (data.data) {
        if (clear) {
          setProducts(
            data.data.readProduct.data.filter(item => !selected.includes(item))
          );
        } else {
          setProducts([
            ...products,
            ...data.data.readProduct.data.filter(
              item => !selected.includes(item)
            )
          ]);
        }
      } else if (data.errors) {
        alert(data.errors);
      }
    } catch (err) {
      alert(err);
    }
  }

  function onChange(_, value) {
    if (value.at(-1)?.quantidade_estoque === 0 && validarEstoque) {
      toast.warning('Produto não disponível em estoque');
      return;
    }
    setSelected(value);
  }

  useEffect(() => {
    loadProducts('', 0, true);
  }, []);

  return (
    <Autocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      noOptionsText="Nenhum produto encontrado"
      options={products}
      value={selected}
      inputValue={inputValue}
      ListboxProps={{
        onScroll: event => {
          const listboxNode = event.currentTarget;
          if (
            Math.round(listboxNode.scrollTop + listboxNode.clientHeight) ===
            listboxNode.scrollHeight - 1
          ) {
            setSkip(skip + 1);
            loadProducts(inputValue, skip + 1);
          }
        }
      }}
      defaultValue={selected}
      filterOptions={options => filterOptions(options, selected)}
      onChange={onChange}
      onInputChange={(_, value, reason) => {
        setSkip(0);
        if (reason == 'reset') {
          loadProducts(inputValue, 0, true);
        } else {
          setInputValue(value);
          loadProducts(value, 0, true);
        }
      }}
      getOptionLabel={option => option?.nome_formal}
      filterSelectedOptions={true}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  );
};

export default ProductAutocomplete;
